
import React from 'react';
import './events.css';

const Events = () => {
    return (
       <div className='bassline_balance_yoga_dj_denver-co_boulder-co__events-padding'>
                <div className='bassline_balance_yoga_dj_denver-co_boulder-co__events-cta'>
                <h1 class='gradient__text'>Join Us</h1>
                <p>Join us, where you will be taken on a unique journey through yoga and music. Get ready to push the boundaries of what you know about wellness and creativity. Meet like-minded folks and immerse yourself in an experience that promises to be energizing, empowering, and fun. Don't miss out on this opportunity to be part of something truly special from the very beginning. Register now to secure your spot and start your journey with us! Also, 🥳</p>
                </div>
            <div className='bassline_balance_yoga_dj_denver-co_boulder-co__events-content'>
                <iframe className='bassline_balance_yoga_dj_denver-co_boulder-co__events-luma' src="https://lu.ma/embed/event/evt-e65I1jdCqiuzoAe/simple" width="750" height="450"></iframe>
            </div>
        </div>
    )
  }
  
  export default Events