import React from 'react';
import './cta.css';

const CTA = () => {
  return (
    <div className='bassline_balance_yoga_dj_denver-co_boulder-co__cta'>
    </div>
  )
}

export default CTA