import React, {useState} from 'react';
import { RiMenu3Line, RiCloseCircleLine } from 'react-icons/ri';
import logo from '../../assets/Bassline-Balance-Logo-Web-Color-1-1.svg';
import './navbar.css';

const Menu = () => (
  <>
          <p><a href='#home'>Home</a></p>
          <p><a href='#about'>About</a></p>
          <p><a href='#rythem'>Ethos</a></p>
          <p><a href='#rythem'>Rhythm</a></p>
  </>
)
const Navbar = () => {
  const[toggleMenu, setToggleMenu] = useState(false);
  return (
    <div className="bassline_balance_yoga_dj_denver-co_boulder-co__navbar">
      <div className="bassline_balance_yoga_dj_denver-co_boulder-co__navbar-links">
        <div className="bassline_balance_yoga_dj_denver-co_boulder-co__navbar-links_logo">
          <img src= {logo} alt="logo"/>
        </div>
        <div className="bassline_balance_yoga_dj_denver-co_boulder-co__navbar-links_container">
         <Menu/>
        </div>
      </div>
      <div className='bassline_balance_yoga_dj_denver-co_boulder-co_navbar-menu'>
        { toggleMenu
        ? <RiCloseCircleLine color='#fff' size={27} onClick={ () => setToggleMenu(false)} />
        : <RiMenu3Line color='#fff' size={27} onClick={() => setToggleMenu(true)} />
        }

        { toggleMenu && (
          <div className='bassline_balance_yoga_dj_denver-co_boulder-co__navbar-menu_container scale-up-center'>
            <div className='bassline_balance_yoga_dj_denver-co_boulder-co__navbar-menu_container-links'>
            <Menu/>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Navbar
