import React from 'react';
import './presskit.css';

const Presskit = () => {
  return (
    <div className='bassline_balance_yoga_dj_denver-co_boulder-co__presskit'>
    </div>
  )
}

export default Presskit;
