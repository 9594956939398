import React from 'react';
import './footer.css';
import Logo from '../../assets/Bassline-Balance-Logo-Web-Color-1-1.svg'

const Footer = () => {
  return (
    <div className='bassline_balance_yoga_dj_denver-co_boulder-co__footer__padding'>
      {/* <div className='bassline_balance_yoga_dj_denver-co_boulder-co__footer-heading'>
        <h1 className='gradient__text'>Do you want to step into the future?</h1>
      </div>

      <div className='bassline_balance_yoga_dj_denver-co_boulder-co__footer-btn'>
      <button type='button'>Sign Up</button>
      </div> */}

      <div className='bassline_balance_yoga_dj_denver-co_boulder-co__footer-links'>


        <div className='bassline_balance_yoga_dj_denver-co_boulder-co__footer-links_logo'>
          <img src={Logo} alt='logo' />
          <p>Boulder Colorado</p>
        </div>
        <div className='bassline_balance_yoga_dj_denver-co_boulder-co__footer-links_div'>
          <h4>Links</h4>
          <a href='https://linktr.ee/basslinebalance'><p className='footer-link'>Social Media</p></a>
          <a href='https://x.com/basslinebalance'><p className='footer-link'>Twitter</p></a>
          <a href='https://www.instagram.com/basslinebalance/'><p className='footer-link'>Instagram</p></a>
          <a href='https://www.youtube.com/@basslinebalance'><p className='footer-link'>YouTube</p></a>

        </div>
        <div className='bassline_balance_yoga_dj_denver-co_boulder-co__footer-links_div'>
          <h4>Company</h4>
          <p>Terms & Conditions</p>
          <p>Privacy Policy</p>
          <p>Contact</p>
        </div>
        <div className='bassline_balance_yoga_dj_denver-co_boulder-co__footer-links_div'>
          <h4>Get in touch</h4>
          <p>Boulder Colorado</p>
          <p>info@baselinebalance.com</p>
        </div>
      </div>
      <div className='bassline_balance_yoga_dj_denver-co_boulder-co__footer-copyright'>
        <p>© 2024 Bassline Balance. All rights reserved. Website by CowboyCrypto.io 🤠</p>
      </div>
    </div>
  )
}

export default Footer