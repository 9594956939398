import React from 'react'
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import { Article, Brand, CTA, Feature, Navbar, Presskit } from './components ';
import { Blog, Features, Footer, Header, Possibility, WhatGPT4, Events } from './containers';
import './App.css';


const App = () => {
  return (
    <div className="App">
      <div className="gradient__bg">
        <Navbar/>
        <Header/>
      </div>
      <div>
      <Events/>
      <Brand/>
      <WhatGPT4/>
      <Features/>
      <Possibility/>
      <CTA/>
      <Blog/>
      <div>
      <Presskit/>
      </div>
      <Footer/>
      </div>
    </div>
  )
}



export default App