import React from 'react';
import './features.css';
import Feature from '../../components /feature/Feature';

const featuresData = [
  {
    title: 'Improving end distrusts instantly',
    text: 'Im baby marfa keytar flannel readymade affogato neutral milk hotel pinterest next level kickstarter meh. Whatever cloud bread pok pok jianbing selvage.',
  },
  {
    title: 'Become the tended active',
    text: 'Im baby marfa keytar flannel readymade affogato neutral milk hotel pinterest next level kickstarter meh. Whatever cloud bread pok pok jianbing selvage.',
  },
  {
    title: 'Message or am nothing',
    text: 'Im baby marfa keytar flannel readymade affogato neutral milk hotel pinterest next level kickstarter meh. Whatever cloud bread pok pok jianbing selvage.',
  },
  {
    title: 'Really boy law county',
    text: 'Im baby marfa keytar flannel readymade affogato neutral milk hotel pinterest next level kickstarter meh. Whatever cloud bread pok pok jianbing selvage.',
  }
]
const Features = () => {
      <div></div>
}

export default Features