import React from 'react';
import './header.css';
import people from '../../assets/Group 81.png'
import ai from '../../assets/BasslineBalance-hero.svg'


const Header = () => {
  return (
    <div className='bassline_balance_yoga_dj_denver-co_boulder-co__header section__padding' id='home'>
      <div className='bassline_balance_yoga_dj_denver-co_boulder-co__header-content'>
        <h1 className='gradient__text'>Welcome to Bassline Balance, Where Yoga Meets Rhythm.</h1>
        <h2>Discover Your Center, Unleash Your Spirit</h2>
        <p>At Bassline Balance, we're not just blending yoga with music, we're crafting a vibrant community where every chord and pose pushes the boundaries of convention. Join us in our quest to dissolve the monochrome of everyday life through immersive real-life and online experiences that invite you to celebrate diversity and creativity. 🥳</p>
      </div>
      <div className='bassline_balance_yoga_dj_denver-co_boulder-co__header-image'>
        <img src={ai} alt='ai'/>
      </div>
    </div>
  )
}

export default Header