import React from 'react';
import './whatGPT4.css';
import Feature from '../../components /feature/Feature';

const WhatGPT4 = () => {
  return (
    <div className="bassline_balance_yoga_dj_denver-co_boulder-co__whatgpt4 section__margin" id="about">
      <div className='bassline_balance_yoga_dj_denver-co_boulder-co__whatgpt4-feature'>
        <Feature title="What is Bassline Balance?" text="Dive into a world where being present becomes second nature. Here, wellness is a rhythm, and every movement a melody. Our events and products are curated to heal, challenge, and expand your horizons. From the physical to the philosophical, we make every moment resonate with energy and empathy."/>
      </div>
      <div className='bassline_balance_yoga_dj_denver-co_boulder-co__whatgpt4-heading'>
        <h1 className='gradient__text'>Join Our Revolution of Rhythm and Reflection</h1>
        <p>Jump Into The Light and Explore The Shadow</p>
      </div>
      <div className='bassline_balance_yoga_dj_denver-co_boulder-co__whatgpt4-container'>
        <Feature title="Get Ready to Explore and Expand" text="You are not just attending an event; you're embarking on a journey. A journey to empowerment, balance, and a deeper understanding of yourself. Let go of the mundane; embrace playfulness and introspection. Feel seen, feel energized, feel appreciated. With us, every step is an invitation to challenge your limits and explore your potential."/>
        <Feature title="Are You Ready to Feel Rooted and Uplifted" text="Step into our circle, where every session is an opportunity to discover something special. Feel the power of being part of something bigger, a place where everyone is welcome, and every story matters. Your journey to personal and collective transformation begins here."/>
        <Feature title="More Than an Event, An Experience" text="Bassline Balance is more than a community; it's a movement. A movement fueled by the charismatic, outgoing spirit of explorers and creators like you. Together, we're setting the stage for a life that's not only lived but celebrated. Stay curious, stay connected, stay inspired."/>
      </div>
    </div>
  )
}

export default WhatGPT4