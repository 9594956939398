import React from 'react';
import './possibility.css';
import possibilityImage from '../../assets/Feature Image.png'
const Possibility = () => {
  return (
    <div className='bassline_balance_yoga_dj_denver-co_boulder-co__possibility section__padding' id='rythem' >
    </div>
  )
}

export default Possibility